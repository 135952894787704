import {commons} from './common';
import {indexs} from './index';
import {products} from './product';
import {vues} from './vues';
//import {demos} from './demo';

$(() => {
	vues();
    commons();
    indexs();
});
