export function vues(){
	const vues = new Vue({
		el : "#WRAPPER",
		data : {
			productShow : 0
		},
		methods : {
			showsProduct : function(num){
				this.productShow = num;
			}
		}
	});
}

