export function indexs(){
	
	//VEGAS
	const target = $(".firstview");
	const photo = new Array();
	for(let i=0; i < target.find(".hide img").length; i++){
		photo[i] = target.find(".hide img").eq(i).attr("src");
	}
	const photos = Object.entries(photo).map(([src, value]) => ({'src': value}));
	target.vegas({
		overlay: false,
		timer:false,
		transitionDuration: 2000,
		delay: 10000,
		animation: 'kenburns',
		animationDuration: 12000,
		loop:false,
		slides: photos
	});
	
	
	const swiper = new Swiper('.swiper-container', {
		slidesPerView: 3,
		spaceBetween: 30,
		loop : true,
		speed: 300,
		simulateTouch: false,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			768:{
				slidesPerView: 1,
				spaceBetween: 0
			}
		}
	});

}